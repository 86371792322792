<template>
  <div class="container">
    <el-container>
      <ui-aside></ui-aside>
      <el-container>
        <el-header><ui-head></ui-head></el-header>
        <div class="el-tabs__nav-scroll">
          <el-tabs v-model="activeIndex"  ref="tabs" size="mini" type="card" closable @tab-remove="removeTab" @tab-click="handTabClick">
            <el-tab-pane
              size="mini"
              v-for="(item, index) in openTab"
              :key="index"
              :label="item.name"
              :name="item.route">
            </el-tab-pane>
          </el-tabs>
        </div>
        <el-main>
          <el-breadcrumb separator="/" style="margin-bottom:16px;margin-top: 4px;" >
            <el-breadcrumb-item>主页面</el-breadcrumb-item>
            <el-breadcrumb-item>{{breadcrumbTitle}}</el-breadcrumb-item>
          </el-breadcrumb>
          <ui-container></ui-container>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

export default {
  data(){
    return{
      breadcrumbTitle:"",
    }
  },
  watch:{
    '$route'(to,from){
      this.breadcrumbTitle = to.meta.title
      let flag = false;
      for(let item of this.openTab){
        if(item.name === to.meta.title){
          this.$store.commit('add_active_index',to.path)
          flag = true;
          break;
        }
      }
      if(!flag){
        this.$store.commit('add_tabs', {route: to.path, name: to.meta.title});
        this.$store.commit('add_active_index', to.path);
      }
    },
    
  },
  computed:{
    openTab(){
      return this.$store.state.openTab;
    },
    activeIndex:{
      get: function () {
        return this.$store.state.activeIndex;
      },
      set: function () {},
    },
  },
  created(){
    this.$store.commit('add_tabs',{route:this.$route.path,name:this.$route.meta.title})
    this.$store.commit('add_active_index',this.$route.path)
    this.breadcrumbTitle = this.$route.meta.title
  },
  methods:{
  
    handTabClick(data){ //点击标签页 
    if(this.$store.state.openTab)
      this.$router.push(data.name)
    },
    removeTab(name){  //删除标签页
      if(name===this.openTab[0].route){
        return
      }
      this.$store.commit('delete_tabs',name)
      if(this.activeIndex===name){
        if(this.openTab && this.openTab.length>=1){
          this.$store.commit('add_active_index',this.openTab[this.openTab.length-1].route)
          this.$router.push({path:this.activeIndex})
        }else{
          this.$router.push('/')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  .el-tabs__nav-scroll{
    padding: 3px 0;
    height: 32px;
     /deep/.el-tabs__item{
      height: 34px !important;
      line-height: 34px !important;
    }
    /deep/.el-tabs__header{
      padding: 0 0 0 6px;
    }
  }
}
.el-main {
  display: block;
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: #F2F3F5;
}
.el-header {
  padding: 0!important;
  line-height: 50px;
  height: 50px !important;
  overflow: hidden;
  box-shadow: 0 1px 4px #d6dadd;
}
body > .el-container {
  margin-bottom: 40px;
}
</style>